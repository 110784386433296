<template>
	<div class="nft-market">
		<my-nft-list-nav :index="current_index" :list="navList" @click="clickHandle"/>
		<div class="MyNFTList-box">
			<div class="d-flex justify-center pt10" v-if="filter_list.length === 0">
				<div class="MyNFTList-nodata">{{ $t('You currently have no cards in this category. You can') }}
					<router-link to="/app/Game/BuyBox">{{ $t('buy boxes') }}</router-link>
					{{ $t('and get your NTF cards.') }}
				</div>
			</div>
			<div class="MyNFTList-card__box">
				<div v-for="(item, index) in filter_list" :key="index">
					<div class="MyNFTList-card__item">
						<img class="card-img" src="@/assets/open-bg.png" alt="">
						<img class="content-img" :src="getCardImgUrl(item.type)" alt="">
						<div class="MyNFTList-card__content">
							<div class="MyNFTList-card__head">
								<span class="MyNFTList-card__number">#{{ item.tokenId }}</span>
								<img class="combination-img" src="@/assets/combination_tag.png" alt="" v-show="cards[item.type].combined_list.length > 0">
							</div>
							<div class="MyNFTList-card__name">
								<div>{{ cards[item.type].zhHans }}</div>
								<div>{{ cards[item.type].en }}</div>
							</div>
						</div>
					</div>

					<div class="nft-price_text">Price: {{ item.price }} AMT</div>

					<v-btn
							:style="[is_mobile ? mobile_btn_styles : pc_btn_styles]"
							color="#EB6930"
							large
							rounded
							@click="_buyNow(item)"
					>{{ $t('BUY NOW') }}</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { orderList } from '@/utils/cardFactory'
import MyNftListNav from '@/views/Game/MyNFT/MyNftListNav'
import cards from '@/data/card'
import { getCardImgUrl } from '@/utils/tools'
import { SessionStorage } from 'storage-manager-js'
import { mapState } from 'vuex'

export default {
	name: 'NFTMarket',
	components: { MyNftListNav },
	data () {
		return {
			current_index: 0,
			navList: [],
			list: [],
			cards,
			pc_btn_styles: {
				height: '44px !important',
				width: '236px',
			},
			mobile_btn_styles: {
				height: '34px !important',
				width: '111px',
			},
		}
	},
	computed: {
		...mapState(['card_nav_list']),
		filter_list () {
			const current_type = this.navList[this.current_index].type
			const list = []
			console.log(this.list)
			if (current_type === 0) {
				return this.list
			} else {
				this.list.map(item => {
					const type = item.type.split('-')[0]
					if (current_type === Number(type)) {
						list.push(item)
					}
				})
			}
			return list
		},
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
	},
	created () {
		this.navList = JSON.parse(JSON.stringify(this.card_nav_list))
		this.orders()
		const { index } = this.$route.query
		if (index) {
			this.current_index = Number(index)
		}
	},
	methods: {
		clickHandle ({ index }) {
			this.current_index = index
		},
		async orders () {
			this.list = await orderList()
			this.list.map(item => {
				const type = item.type.split('-')[0]
				this.navList.map(nav => {
					if (nav.type === Number(type)) {
						nav.value++
					}
				})
			})

			this.navList[0].value = this.list.length

			SessionStorage.set('MARKET_NAV', this.navList)
		},
		getCardImgUrl (filename, type) {
			return getCardImgUrl(filename, type)
		},
		_buyNow (item) {
			SessionStorage.set('BUY_INFO', { ...item, data: this.cards[item.type] })
			this.$router.push(`/app/Buy?index=${this.current_index}`)
		},
	},
}

</script>

<style lang="scss" scoped>
// pc
@media (min-width: 960px) {
	.nft-market {
		padding: 80px 0;
	}
}
// mobile
@media (max-width: 960px) {
	.nft-market {
		padding: 15px;
	}
}
</style>
